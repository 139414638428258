import React, { useEffect } from 'react';

function HandlePostMessages(): JSX.Element {
    useEffect(() => {
        const setupLocalStorage = (message: { origin: string; data: string }) => {
            // TODO: add whitelist for message.origin
            try {
                const data = JSON.parse(message.data);
                const { token, currentOrganization } = data;
                if (token && currentOrganization) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('currentOrganization', currentOrganization);
                    localStorage.setItem('platformNotiticationShown', 'shown');
                    localStorage.setItem('featuresNotiticationShown', 'shown');
                }
            } catch (error) {
                console.log(`Exception during automatic login attempt for CVAT: ${error}`);
            }
        };
        window.addEventListener('message', setupLocalStorage);

        return () => {
            window.removeEventListener('message', setupLocalStorage);
        };
    }, []);

    return <div>dummy page for eleval control iframe communication</div>;
}

export default HandlePostMessages;
